<template>
  <div class="desktopLayoutClass" >
    <v-app style="min-height: 80vh !important;">
      <!-- Dialog Start -->
      <div>
        <v-row justify="center">
          <v-dialog v-model="auth_error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
                {{$t("Warning_Message.Unauthorized")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogUnauthorized">
                {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
              {{$t("Warning_Message.Something_Went_Wrong")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogError">
                  {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_template" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
              {{ error_message }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeErrorTemplate">
                  {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="upload_success" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
              Upload success
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="success" text @click="closeUploadSuccess">
                  {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="file_type_ext" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                  {{ $t("Customer.ViewAppointment.File_Supported") }}
              </v-card-title>
              <br>
              <v-card-subtitle>{{ $t("Customer.ViewAppointment.Supported_Files") }}</v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeFileExt"
                >
                  {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="upload_error" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
              {{ $t("Customer.ViewAppointment.Upload_Error") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="error" text @click="upload_error = false">
                  {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <!-- uplaod-file popup-start -->
      <div>
        <v-row justify="center">
          <v-overlay :value="overlay">
            <v-dialog v-model="fileUploadModal" fullscreen transition="dialog-bottom-transition" style="background-color: #00000050 !important;" max-width="425" >
              <v-card style=" border-radius: 30px 30px 0px 0px !important;">
                <v-card-title class="fileUploadPopUpClass">
                  <div>
                    <p style="color: #1467BF;font-weight:bold;margin-top:10px">{{ $t("Customer.ViewAppointment.Upload_Files") }}</p>
                  </div>
                  <div>
                    <v-btn v-if="fileSaveCloseBtnFlag" icon @click="fileUploadModal=false, overlay=false">
                      <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/popupClose.svg" >
                    </v-btn>
                  </div>
                </v-card-title>
                <v-card-text align="left" style="font-weight: bolder; font-size:12px">{{ $t("Customer.ViewAppointment.Max_Allowed_Size") }}</v-card-text>
                <v-card-actions>
                  <v-file-input
                    v-model="uploadedFile"
                    solo
                    prepend-icon=""
                    @change="fileInputChange"
                    accept="image/jpeg,image/png,	image/webp,application/pdf,	application/msword,text/csv,text/plain"
                    :placeholder="Upload_File"
                  >
                  </v-file-input>
                </v-card-actions>

                <div style="overflow:scroll; height:400px;">
                  <div v-for="file in upload_prescription_array" :key="file.name" class="fileList" >
                    <div style="display:flex; flex-direction:row;justify-content: space-between;">
                      <div>
                        <p class="fileNameClass"> {{file.name.split("#@#")[1]}}</p>
                      </div>

                      <div>
                        <span @click="deleteFileUploaded(file.name)" style="font-weight:bold; color:red; padding:2px" v-if="file.uploadStatus != 'Uploading'">
                          <img style="padding-top:15px;" src="https://d3vsnl8idgwrlw.cloudfront.net/closeBtn.svg">
                        </span>
                      </div>
                    </div>
                    <div>
                      <div v-if="file.uploadStatus == 'Uploading'">
                        <p class="fileStatusUploadingClass"> {{ $t("Customer.ViewAppointment.Uploading") }}
                          <span class="dots-cont"> <span class="dot dot-1"></span> <span class="dot dot-2"></span> <span class="dot dot-3"></span> </span>
                        </p>
                      </div>
                      <div v-else-if="file.uploadStatus == 'Completed'">
                        <p class="fileStatusCompletedClass"> {{ $t("Customer.ViewAppointment.Completed") }}
                          <span>
                            <img style="width:16px;margin:5px 0px 0px 6px" src="https://d3vsnl8idgwrlw.cloudfront.net/success_tick.svg" >
                          </span>
                        </p>
                      </div>
                      <div v-else-if="file.uploadStatus == 'Failed'">
                        <p class="fileStatusFailedClass"> {{ $t("Customer.ViewAppointment.Failed") }}
                          <span>
                            <img style="width:16px;margin:5px 0px 0px 6px" src="https://d3vsnl8idgwrlw.cloudfront.net/closeBtn.svg" >
                          </span>
                        </p>
                      </div>
                      <div v-else-if="file.uploadStatus == 'LargeFile'">
                        <p class="fileStatusFailedClass"> {{ $t("Customer.ViewAppointment.Large_Files") }}
                          <span>
                            <img style="width:16px;margin:5px 0px 0px 6px" src="https://d3vsnl8idgwrlw.cloudfront.net/closeBtn.svg" >
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <v-btn @click="saveFiles" width="80%" color="primary" :disabled="!fileSaveBtnFlag" style="position: fixed;left: 50%;transform: translate(-50%, 0); bottom:10px">
                    {{ $t("Customer.BookAppointment.Save_Files") }}
                  </v-btn>
                </div>
              </v-card>
            </v-dialog>
          </v-overlay>
        </v-row>
      </div>
      <!-- uplaod file popup-end -->

      <!-- Dialog Close -->

      <div v-show="show_header" class="header" style="display: flex; flex-direction: row; padding-top: 10px; padding-left: 5px; background-color: white;">
        <div style="display: flex; flex-direction: row; width: 100%">
          <div style="width: 10%; padding-top: 6px" v-on:click="redirectToHomePage()" align="left">
            <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
          </div>
          <div style="width: 90%" align="left">
            <span style="font-weight: 600; font-size: 15pt">{{ $t("Customer.ViewAppointment.Booking_Details") }}</span>
          </div>
        </div>
      </div>

      <div v-if="!isLoadingBookingDetails">
        <div class="viewAppointmentBtnClass" v-if="prescription">
          <v-btn style="height: 44px; margin-left:auto; margin-right: auto; width: 100%; padding:" class="primary" @click="showPrescription">
            {{ $t("Customer.ViewAppointment.View_Prescription") }}
          </v-btn>
        </div>
        <div style="display: flex; flex-direction: column; margin: 20px;" align="left" >
          <div class="imgClass" style="margin: auto;" v-if="isCompleted == true && feedback_inserted == true">
            <div v-if="this.$i18n.locale == 'mm'" >
              <img style="width:100%;" src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/review_success_mm.svg"/>
            </div>
            <div v-else>
              <img style="width:100%;" src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/review_success.svg"/>
            </div>
          </div>
          <v-card
            style="box-shadow: 0px 10px 20px 0px #0547891a; border: 1px solid #e4f5ff; border-radius: 8px;">
            <div style="display: flex; flex-direction: row; padding: 10px">
              <div style="flex: 1">
                <span class="css-title"> {{ $t("Customer.ViewAppointment.Booking_ID") }}:</span>
              </div>
              <div style="flex: 1; text-align: right">
                <span class="content">{{ book_id }}</span>
              </div>
            </div>
            <v-divider />
            <div style="display: flex; flex-direction: row; padding: 10px">
              <div style="flex: 1">
                <span class="css-title"> {{ $t("Customer.ViewAppointment.Name") }}:</span>
              </div>
              <div style="flex: 1; text-align: right">
                <span class="content">{{ nominee }}</span>
              </div>
            </div>
            <v-divider />
            <div style="display: flex; flex-direction: row; padding: 10px">
              <div style="flex: 1">
                <span class="css-title"> {{ $t("Customer.ViewAppointment.Specialization") }}:</span>
              </div>
              <div style="flex: 1; text-align: right">
                <span class="content">{{ specialization }}</span>
              </div>
            </div>
            <v-divider />
            <div style="display: flex; flex-direction: row; padding: 10px">
              <div style="flex: 1">
                <span class="css-title"> {{ $t("Customer.ViewAppointment.Doctor") }}:</span>
              </div>
              <div style="flex: 1; text-align: right">
                <span class="content">{{ doctor_name }}</span>
              </div>
            </div>
            <v-divider />
            <div style="display: flex; flex-direction: row; padding: 10px">
              <div style="flex: 1">
                <span class="css-title"> {{ $t("Customer.ViewAppointment.Date") }}:</span>
              </div>
              <div style="flex: 1; text-align: right">
                <span class="content">
                  <DateRendered :dateVal=" date "> </DateRendered>
                  </span>
              </div>
            </div>
            <v-divider />
            <div style="display: flex; flex-direction: row; padding: 10px">
              <div style="flex: 1">
                <span class="css-title"> {{ $t("Customer.ViewAppointment.Time") }}:</span>
              </div>
              <div style="flex: 1; text-align: right">
                <span class="content"><TimeRender :timeSlot="time_slot"> </TimeRender></span>
              </div>
            </div>
            <v-divider />
            <div style="display: flex; flex-direction: row; padding: 10px"  v-if="discountedAmount != 0">
              <div style="flex: 1">
                <span class="css-title" v-if="payment_status == 'PAID'">
                  {{ $t("Customer.ViewAppointment.Sub_Total") }}</span
                >
                <span class="css-title" v-else> {{ $t('Customer.ViewAppointment.Payment') }} </span>
              </div>
              <div
                v-if="payment_status == 'PAID'"
                style="flex: 1; text-align: right"
              >
                <span class="content">{{ booking_details.total_amount }} {{ $t("Customer.ViewAppointment.MMK") }}</span>
              </div>
              <div v-else style="flex: 1; text-align: right">
                <span class="content">{{ payment_status }}</span>
              </div>
            </div>

            <v-divider  v-if="discountedAmount != 0" />
            <div style="display: flex; flex-direction: row; padding: 10px">
              <div style="flex: 1">
                <span class="css-title" v-if="discountedAmount != 0">
                  {{ $t("Customer.ViewAppointment.Discount") }}</span
                >
                <!-- <span class="css-title" v-else> Payment: </span> -->
              </div>
              <div
                v-if="discountedAmount != 0"
                style="flex: 1; text-align: right"
              >
                <span class="content"> {{discountedAmount}} {{ $t("Customer.ViewAppointment.MMK") }}</span>
              </div>
              <!-- <div v-else style="flex: 1; text-align: right">
                <span class="content">{{ payment_status }}</span>
              </div> -->
            </div>

            <!-- <v-divider v-if="discountedAmount != 0"></v-divider> -->

            <div style="display: flex; flex-direction: row; padding: 10px">
              <div style="flex: 1">
                <span class="css-title" v-if="payment_status == 'PAID'">
                  {{ $t("Customer.ViewAppointment.Amount") }}:</span
                >
                <span class="css-title" v-else-if="discountedAmount != 0"> Payment: </span>
              </div>
              <div
                v-if="payment_status == 'PAID'"
                style="flex: 1; text-align: right"
              >
                <span class="content">{{ amount }} {{ $t("Customer.ViewAppointment.MMK") }}</span>
              </div>
              <div v-else style="flex: 1; text-align: right">
                <span class="content">{{ payment_status }}</span>
              </div>
            </div>
            <v-divider></v-divider>
            <div style='padding:10px'>
              <div  style="flex:1;">
              <span class="css-title"> {{ $t("Customer.ViewAppointment.Description") }}:</span>
              </div>

              <div style="flex:1;text-align: left; overflow: scroll; height: 10vh; overflow-x:hidden">
                <span class="content">{{description}}</span>
              </div>
            </div>
          </v-card>
          <v-card v-if="file_attachment.length != 0" class="fileAttachementsCard">
            <div class="fileTitileDiv">
            <p class="attachementsTitle">{{ $t("Customer.ViewAppointment.Attachments") }}</p>
            <v-divider></v-divider>
            </div>
            <div class="attachementWrapCalss">
            <div v-for="file in filedata" :key="file.name" >
              <div v-if="file.name.split('.').slice(-1) == 'pdf'" class="individualFile">
                <div class="attachementClass" @click="fileOpen(file.name)">
                <!-- <div class="attachementClass"> -->
                  <img
                  class="imgattachementClass"
                  src="https://d3vsnl8idgwrlw.cloudfront.net/pdfIcon.svg"
                  >
                  <!-- @click="downloadItem(file)" > -->
                </div>
                <div >
                  <p class="fileNameClass">{{file.name.split("#@#")[1]}}</p>
                </div>
              </div>
              <div v-else-if="file.name.split('.').slice(-1) == 'jpg' ||file.name.split('.')[1] == 'jpeg' ||file.name.split('.')[1] == 'png'" class="individualFile">
                <div class="attachementClass" @click="fileOpen(file.name)" >
                <!-- <div class="attachementClass"> -->
                  <img
                    class="thumbnailattachementClass"
                    :src=file.URL
                    >
                    <!-- @click="downloadItem(file)" > -->

                  <!-- src="https://d3vsnl8idgwrlw.cloudfront.net/imageIcon.svg" -->
                </div>
                <div >
                  <p class="fileNameClass">{{file.name.split("#@#")[1]}}</p>
                </div>
              </div>
              <div v-else class="individualFile">
                <div class="attachementClass" @click="fileOpen(file.name)">
                <!-- <div class="attachementClass"> -->
                  <img
                  class="imgattachementClass" src="https://d3vsnl8idgwrlw.cloudfront.net/gennericAttachementIcon.svg"
                  >
                  <!-- class="imgattachementClass" src="https://d3vsnl8idgwrlw.cloudfront.net/gennericAttachementIcon.svg" @click="downloadItem(file)" -->
                </div>
                <div >
                  <p class="fileNameClass">{{file.name.split("#@#")[1]}}</p>
                </div>
              </div>
            </div>
            </div>
        </v-card>
        </div>
        <!-- <div v-if="file_attachment.length == 0" style="margin:20px; display: flex; justify-content: space-around;">
          <v-file-input v-show="false"
            v-model="selectedFiles"
            color="primary"
            counter
            :label="translation_attachment"
            multiple
            append-icon="mdi-folder-open-outline"
            prepend-icon=""
            outlined
            small-chips
            accept="image/jpeg,image/png, image/webp,application/pdf, application/msword,text/csv,text/plain"
            :show-size="1000"
            style="flex-grow: 20;"
            @change="InitBase64()"
            >
          <template v-slot:selection="{ index, text }">
              <v-chip
                v-if="index < 1"
                color="primary"
                dark
                label
                small
              >
                {{ text }}
              </v-chip>
                <span
                  v-else-if="index === 1"
                  class="text-overline grey--text text--darken-3 mx-2"
                >
                  +{{ selectedFiles.length - 1 }} File(s)
                </span>
            </template>
          </v-file-input>
          <v-btn :disabled="uploadButton || this.selectedFiles.length == 0" style="flex-grow:1; margin-left: 6px; margin-right: 6px; padding:18px; height: 55px" class="primary" @click="uploadFiles">
            <v-icon v-if="!loading_indication">mdi-upload-outline</v-icon>
            <v-progress-circular indeterminate color="primary" v-else></v-progress-circular>
          </v-btn>
        </div> -->
      </div>
    <div style="margin-top:10%;" v-else>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <div style="position: relative; top: 10px; bottom: 0; width: 100%;">
      <div class="JoinClass">
        <v-btn class="primary" v-if="display_addPres_btn" style="height: 44px;margin-left:auto; margin-right: auto; width: 100%;" @click="addPrescription_popUp">
          {{ $t("Customer.ViewAppointment.Add_Attachments") }}
        </v-btn>
      </div>
      <div class="JoinClass" v-if="isLinkActive == true && !isLoadingBookingDetails">
        <v-btn class="primary" style="height: 44px;margin-left:auto; margin-right: auto; width: 100%;" @click="redirectToCallpage(appointment_id)">
          <v-icon left>
          </v-icon>
          {{ $t("Customer.ViewAppointment.Join") }}
        </v-btn>
      </div>
      <div class="InsertFeedbackClass" v-else-if="isCompleted == true && feedback_inserted == false">
        <!--  -->
        <v-btn @click="give_feedback()" style="height: 44px;margin-left:auto; margin-right: auto; width: 100%; color: white; background-color: #f2924c;">
          {{ $t("Customer.ViewAppointment.Share_Your_Review") }}
        </v-btn>
      </div>
    </div>
    </v-app>
  </div>
</template>

<script>
  import NavBar from "../../../views/navbar.vue";
  import axios from "axios";
  import bottomnavbar from "./bottomnavbar.vue";
  import DateRendered from './dateRender.vue';
  import TimeRender from './TimeRender.vue';
  export default {
    name: "CustomerHome",
    data() {
      return {
        appointment_id: "",
        isLoadingBookingDetails:true,
        auth_error_enable: false,
        error_enable: false,
        booking_id: "",
        nominee: "",
        success_flag: 0,
        date: "",
        time_slot: "",
        specialization: "",
        doctor_name: "",
        amount: 0,
        link_generated: true,
        payment_status: "Pending",
        book_id: 0,
        booking_details: "",
        feedback: false,
        epoch_app_time: "",
        overlay:false,
        fileUploadModal: false,
        fileSaveCloseBtnFlag:true,
        fileType_extension:"",
        fileSaveBtnFlag:false,
        uploadedFile:[],
        upload_prescription_array:[],
        filesArray:[],
        display_addPres_btn:false,
        fileTypes:["image/jpeg","image/png","image/webp","application/pdf","application/msword","text/csv","text/plain"],
        headers: [
          {
            text: "Meta Key",
            value: "Meta_Key",
            align: "start",
          },
          {
            text: "Meta Value",
            value: "Meta_Value",
          },
        ],
        app_details: [],
        enquiry_name: "",
        description: "",
        notes: "",
        appointment_params_id : "",
        customer_id : "",
        customer_name : "",
        currentCustomer : "",
        feedback_inserted: false,
        isLinkActive: false,
        isCompleted : false,
        prescription: false,
        isMobile : false,
        file_attachment: "",
        selectedFiles: [],
        translation_attachment: "Browse files",
        uploadButton: true,
        attachmentFiles: [],
        loading_indication: false,
        upload_success: false,
        upload_error: false,
        show_header: true,
        error_template: false,
        file_type_ext:false,
        error_message: "",
        imgSrc:"",
        filedata:[],
        discountedAmount:'',
      };
    },
    components: {
      NavBar,
      bottomnavbar,
      DateRendered,
      TimeRender
    },
    methods: {
      downloadItem(file)
      {
        var name_arr = file.name.split("/");
        var name = name_arr[name_arr.length -1];
        var read_body = {
          file_name: name,
          token : this.currentCustomer,
          typeOfUser : "CUSTOMER",
        };

        axios.post(process.env.VUE_APP_BACKEND_URL+"/getPresignedURLRead",read_body)
        .then((readSuccess) =>{
          axios.get(readSuccess.data.data, { responseType: 'blob' })
          .then((response) => {
            const blob = new Blob([response.data], { type: response.data.type });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = file.name;
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch((error) => {
            console.log("Error Downloading file: " + error);
          });
        })
        .catch((readError) =>{
          console.log(readError);
        });
      },
      closeUploadSuccess()
      {
        this.upload_success = false
        this.$router.go();
      },
      closeFileExt(){
        this.file_type_ext = false;
      },
      InitBase64() {
        this.uploadButton = true;
        this.loading_indication = true;
        this.attachmentFiles = [];
        if(this.selectedFiles.length == 0){
          // Nothing
          this.uploadButton = false;
          this.loading_indication = false;
        }
        else{
          // this.selectedFiles.forEach((x) =>{
          //   this.getBase64(x)
          // });
          for (var i = 0; i < this.selectedFiles.length; i++) {
            this.getBase64(this.selectedFiles[i]);

            // presigned method
            var active_file = this.selectedFiles[i];
            // console.log(active_file.type);
            var getPresignedURLBody = {
              'file_name': active_file.name.split(" ").join("_").toLowerCase(),
              'file_type': active_file.type,
              "token" : this.currentCustomer,
              "typeOfUser" : "CUSTOMER"
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/getPresignedURLWrite", getPresignedURLBody)
            .then((getPresignedURLSuccess) =>{
              var fields = getPresignedURLSuccess.data.data.fields;
              var url = getPresignedURLSuccess.data.data.url;
              var post_body = {
                bucket: fields.bucket,
                ...fields,
                'Content-Type': active_file.type,
                file: active_file
              };
              const formData = new FormData();
              for(const name in post_body)
              {
                formData.append(name,post_body[name]);
              }
              axios.post(url, formData)
              .then((imgUploadSuccess) =>{
                this.error_template = true;
                this.error_message = "Upload to s3 done";
              })
              .catch((imgUploadFail) =>{
                this.error_template = true;
                this.error_message = "Error while uploading to S3 " + "\n" + imgUploadFail;
              });
            })
            .catch((getPresignedURLError) =>{
              this.error_template = true;
              this.error_message = "Error while getting presigned body" + "\n" + getPresignedURLError;
            });
          }
        }
      },
      uuidv4() {
            return 'xxxxxx#@#'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
      addPrescription_popUp(){
        this.fileUploadModal=true;
        this.overlay = true;
        this.fileSaveCloseBtnFlag=true;
        this.fileSaveCloseBtnFlag=true;
      },
      saveFiles(){
      this.overlay = false
      this.fileUploadModal = false
      //refresh the code
      location.reload();
    },
      fileInputChange(){
      // this.fileSaveBtnFlag = false
      // this.fileSaveCloseBtnFlag = false
        if(this.uploadedFile.name)
        {
          if(this.fileTypes.includes(this.uploadedFile.type))
          {
            var slice = this.uploadedFile.slice(0,4);
            var reader = new FileReader();
            reader.readAsArrayBuffer(slice);  // Read the chunk file and return to blob
            reader.onload =  function(e) {
              var file_extensions = ["89504e47","25504446","ffd8ffe0",
                                    "ffd8ffe1","ffd8ffe8","504B030414000600",
                                    "d0cf11e0","0908100000060500","efbbbf",
                                    "fffe","feff","fffe0000","0000feff","0efeff"];
              var fileFlag=0;
              var buffer = reader.result;          // The result ArrayBuffer
              var view = new DataView(buffer);      // Get access to the result bytes
              var signature = view.getUint32(0, false).toString(16);  // Read 4 bytes, big-endian，return hex string
              this.fileType_extension=signature;
              this.fileType_extension=this.fileType_extension;
              if(this.uploadedFile.type=="text/csv" || this.uploadedFile.type=="image/jpeg" || this.uploadedFile.type=="image/png" || this.uploadedFile.type=="application/pdf"
              || this.uploadedFile.type=="application/msword"){
                fileFlag=1
              }
              if(file_extensions.includes(this.fileType_extension)||fileFlag==1){
                this.fileSaveBtnFlag = false
                this.fileSaveCloseBtnFlag = false
                var fileName = this.uuidv4()+this.uploadedFile.name.split(" ").join("_").toLowerCase();
                this.upload_prescription_array.push({name:fileName, uploadStatus:"Uploading", size: this.uploadedFile.size, type: this.uploadedFile.type})
                var active_file = this.uploadedFile;
                var getPresignedURLBody = {
                  'file_name': fileName,
                  'file_type': active_file.type,
                  "token" : this.currentCustomer,
                  "typeOfUser" : "CUSTOMER"
                }
                if(this.uploadedFile.size >= 10485760)
                {
                  this.upload_prescription_array.forEach(function(item){
                    if(item.name == fileName){
                      item.uploadStatus = "LargeFile"
                    }
                  });
                  var isTrue = this.upload_prescription_array.every(obj => obj.uploadStatus != 'Uploading');
                  if(isTrue)
                  {
                    this.fileSaveBtnFlag = true;
                  }
                }
                else
                {
                  axios.post(process.env.VUE_APP_BACKEND_URL+"/getPresignedURLWrite", getPresignedURLBody)
                  .then((getPresignedURLSuccess) =>{
                    var fields = getPresignedURLSuccess.data.data.fields;
                    var url = getPresignedURLSuccess.data.data.url;
                    var post_body = {
                      bucket: fields.bucket,
                      ...fields,
                      'Content-Type': active_file.type,
                      file: active_file
                    };
                    const formData = new FormData();
                    for(const name in post_body)
                    {
                      formData.append(name,post_body[name]);
                    }
                    axios.post(url, formData)
                    .then((imgUploadSuccess) =>{
                                this.fileSaveCloseBtnFlag = true
                                // let completedFiles = []
                                var save_file_body = {
                                    'booking_id': this.booking_id,
                                    'prescription': fileName,
                                    "token" : this.currentCustomer,
                                    "typeOfUser" : "CUSTOMER"
                                }
                                axios.post(process.env.VUE_APP_BACKEND_URL+"/addPrescription", save_file_body)
                                .then((saveSuccess) =>{
                                    var newUploadedFile = this.upload_prescription_array.find(obj => obj.name == fileName)
                                    this.upload_prescription_array.forEach(function(item){
                                        if(item.name == newUploadedFile.name ){
                                            //this.currentSampleLength=this.sampleFileLists.length
                                            item.uploadStatus = "Completed";
                                        }
                                    }.bind(this))
                                    var isTrue = this.upload_prescription_array.every(obj => obj.uploadStatus != 'Uploading');
                                    if(isTrue)
                                    {
                                        this.fileSaveBtnFlag = true;
                                    }
                                })
                                .catch((saveError) =>{
                                    console.log(saveError);
                                    this.save_file_alert = true;
                                });
                            //localStorage.setItem('fileStatus', JSON.stringify(this.filesArray))
                            })
                    .catch((imgUploadFail) =>{
                      // this.fileSaveBtnFlag = true
                      this.fileSaveCloseBtnFlag = true
                      console.log("Error while uploading to s3", imgUploadFail);
                      var uploadedFile = this.upload_prescription_array.find(obj => obj.name == fileName)
                      this.upload_prescription_array.forEach(function(item){
                        if(item.name == uploadedFile.name && item.size > 10000000 ){
                          item.uploadStatus = "LargeFile"
                        }
                        else if(item.name == uploadedFile.name) {
                          item.uploadStatus = "Failed"
                        }
                      });
                      var isTrue = this.upload_prescription_array.every(obj => obj.uploadStatus != 'Uploading');
                      if(isTrue)
                      {
                        this.fileSaveBtnFlag = true;
                      }
                    });
                  })
                  .catch((getPresignedURLError) =>{
                    this.fileSaveBtnFlag = true
                    this.fileSaveCloseBtnFlag = true
                    this.error_template = true;
                    this.error_message = "Error while getting presigned body" + "\n" + getPresignedURLError;
                  });
                }
                this.uploadedFile=[];
              }
              else{
                this.file_type_ext = true;
              }
            this.uploadedFile = [];
            }.bind(this)
          }
          else{
            this.uploadedFile = [];
            this.file_type_ext = true;
            //alert("File must be .jpeg, .png, .pdf, .csv, .webp, .msword, .plain");
          }
        }
    },
    deleteFileUploaded(filename){
      var deleteFilebody = {
        token                 : this.currentCustomer,
        typeOfUser            : "CUSTOMER",
        deleteAttachmentFile  : filename,
        appointment_id        : this.appointment_id,
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/deleteAttachments",deleteFilebody)
        .then((deleteSuccess) =>{
          this.upload_prescription_array.splice(this.upload_prescription_array.findIndex(fileIndex => fileIndex.name == filename),1)
          var newArray = this.upload_prescription_array.filter(function (el) {
            return el.uploadStatus == "Completed"
          });
          this.count_success = newArray.length;
          // window.open(readSuccess.data.data,'_blank');
        })
        .catch((readError) =>{
          console.log(readError);
          //window.alert("Error while reading the file");
        });
    },
      getBase64(e){
        var allFiles = [];
        let reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          // Make a fileInfo Object
          let fileInfo = {
            name: e.name,
            type: e.type,
            size: Math.round(e.size / 1000)+' kB',
            base64: reader.result,
            file: e
          };
          this.attachmentFiles.push(fileInfo);
          if(this.attachmentFiles.length == this.selectedFiles.length){
            // this.uploadFiles();
            this.loading_indication = false;
            this.uploadButton = false;
          }
        }
      },
      closeDialogUnauthorized(){
      this.auth_error_enable = false;
      this.$router.push({
          name: "Login",
        });
      },
      closeDialogError(){
        this.error_enable = false;
      },
      closeErrorTemplate(){
        this.error_template = false;
      },
      showPrescription(){
        this.$router.push({
          path:"/customer/viewPrescription/"+this.book_id
        });
      },
      give_feedback() {
        this.$router.push({
          path: "/customer/feedback/" + this.booking_id,
        });
      },
      redirectToHomePage() {
        this.$router.go(-1);
      },
      redirectToCallpage(meetingId) {
        this.$router.push({
         path: '/customer/videocallTwilio/'+ meetingId
       });
      },
      ViewFile(filename)
      {
       var name_arr = filename.split("/");
       var name = name_arr[name_arr.length -1];
        var read_body = {
          file_name: name,
          token : this.currentCustomer,
          typeOfUser : "CUSTOMER",
        };
        axios.post(process.env.VUE_APP_BACKEND_URL+"/getPresignedURLRead",read_body)
        .then((readSuccess) =>{
          this.imgSrc = readSuccess.data.data;
          this.filedata.push({name:filename, URL:this.imgSrc})
          // window.open(readSuccess.data.data,'_blank');
        })
        .catch((readError) =>{
          console.log(readError);
          //window.alert("Error while reading the file");
        });
      },
      fileOpen(filename){
       var name_arr = filename.split("/");
       var name = name_arr[name_arr.length -1];
        var read_body = {
          file_name: name,
          token : this.currentCustomer,
          typeOfUser : "CUSTOMER",
        };

        //safar users
        // let win_ref = window.open("about:blank", "_blank");
        axios.post(process.env.VUE_APP_BACKEND_URL+"/getPresignedURLRead",read_body)
        .then((readSuccess) =>{
          window.open(readSuccess.data.data,'_blank');
          //win_ref.location = readSuccess.data.data;
        })
        .catch((readError) =>{
          console.log(readError);
          //window.alert("Error while reading the file");
        });
        //  window.open(fileUrl,'_blank');
      },
      userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'viewAppointment',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'viewAppointment',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
    },
    mounted() {
      // FB.CustomerChat.hide();
      // document.title = "View Appointment"
      var userAgent = navigator.userAgent
      if(userAgent.includes("kbzpay")){
        this.show_header = false;
      }
      if(this.$store.state.locale == "en") {
        document.title = "View Appointment";
        this.Upload_File = "Click here to upload files"
      } else {
        document.title = "ရက်ချိန်းကြည့်ရှုရန်";
        this.Upload_File = "ဖိုင်တင်ရန် ဒီနေရာကို နှိပ်ပါ။"
      }

      this.isMobile = this.$device.mobile;
      this.currentCustomer = this.$cookies.get("customerToken");
      if(this.$store.state.locale !== "")
      {
        this.$i18n.locale =  this.$store.state.locale;
      }
      else
      {
        this.$i18n.locale = "mm";
        this.$store.dispatch("changeLocale", this.$i18n.locale);
      }
  		var access = this.$cookies.get("access");
      if(!this.currentCustomer){
        this.$router.push({
          name : "Login"
        })
      }
      else{
        //logging page hit - start
        let x =  localStorage.getItem("currentURL")
        let pageName = "ViewAppointmentScreen";
        if( x == pageName)
          x = "";
        localStorage.setItem("currentURL", pageName)
        var pageAccessBody = {
          token: this.currentCustomer,
          pageName: pageName,
          previousPage: x ? x : "START"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
        .then((logSuccess) =>{
        })
        .catch((logError) =>{
        });
        //logging page hit - end
        var params = this.$route.params;
        var booking_id = params["booking_id"];
        this.booking_id = booking_id;
        var viewParticularAppointmentPageData = {
          "token" : this.currentCustomer,
          "bookingId" : this.booking_id,
          "typeOfUser" : "CUSTOMER"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/viewParticularAppointmentPage",viewParticularAppointmentPageData)
        .then((viewParticularAppointmentPageResponse) => {
          // console.log("Response",viewParticularAppointmentPageResponse.data.thumbnailData)
          // this.customer_id = viewParticularAppointmentPageResponse.data.customer._id;
          this.customer_name = viewParticularAppointmentPageResponse.data.customer.customer_name;
          this.booking_details = viewParticularAppointmentPageResponse.data.data;
            if (Object.keys(this.booking_details).length > 0) {
              this.appointment_id = this.booking_details._id;
              this.book_id = this.booking_details.appointment_reference_number;
              this.payment_status = this.booking_details.payment_status;
              this.nominee = this.booking_details.booked_for_name;
              this.date = this.booking_details.date_of_appointment;
              this.time_slot = this.booking_details.time_slot;
              this.amount = this.booking_details.amount;
              this.description = this.booking_details.description;
              this.epoch_app_time = this.booking_details.booking_epoch_time;
              // this.notes = this.booking_details.doctor_notes;
              this.feedback_inserted = this.booking_details.feedback_inserted;
              this.prescription = this.booking_details.prescription_reference_number;
              this.file_attachment = this.booking_details.file_attachment;
              this.discountedAmount = this.booking_details.total_amount - this.amount;
              this.file_attachment.forEach((fileName)=>{
                  this.ViewFile(fileName)
              });
              this.specialization = this.$i18n.locale == 'mm' ? this.booking_details.specialization_name_mm : this.booking_details.specialization_name;
              this.doctor_name = this.$i18n.locale == 'mm' ? this.booking_details.doctor_name_mm : this.booking_details.doctor_name;
              this.isCompleted = this.booking_details.status == "COMPLETED" ? true:false;
              if(this.$i18n.locale == 'en')
              {
                this.translation_attachment = 'Browse reports'
              }
              else if(this.$i18n.locale == 'mm')
              {
                this.translation_attachment = 'တင်ပြလိုသောဖိုင်များ';
              }
              if(this.prescription && this.prescription != '')
              {
                this.prescription = true;
              }
              else{
                this.prescription = false;
              }
              var dateNow = new Date();
              var get_diff = new Date(this.booking_details.booking_epoch_time).getTime() - dateNow.getTime()
              var end_diff = new Date((this.booking_details.booking_closing_epoch_time)) - dateNow.getTime()
              this.isLinkActive = (get_diff < 600000 && end_diff > 0) ? true : false;
             // this.calculate_hours(dateNow, new Date((this.booking_details.booking_closing_epoch_time)))
              var diffrence_in_time =(new Date((this.booking_details.booking_closing_epoch_time)) - dateNow.getTime()) / 1000;
              diffrence_in_time /= (60);
              if (diffrence_in_time<5){
              this.display_addPres_btn=false
              }
              else{
              this.display_addPres_btn=true
              }
              // var date_time = this.date + " " + this.time_slot.split("-")[0];
              // var ending_time = new Date(this.date+" "+this.time_slot.split("-")[1]+":00 GMT+0630").getTime() + (3 * 60 * 1000); //3 minutes grace time
              // this.isLinkActive = (new Date(this.epoch_app_time).getTime() - new Date().getTime() < 600000 && ((new Date(ending_time).getTime() - new Date().getTime() > 0)))? true : false;
              this.app_details.push(
                {
                  Meta_Key: "Booking Id",
                  Meta_Value: this.book_id,
                },
                {
                  Meta_Key: "Name",
                  Meta_Value: this.nominee,
                },
                {
                  Meta_Key: "Reason",
                  Meta_Value: this.enquiry_name,
                },
                {
                  Meta_Key: "Date",
                  Meta_Value: this.date,
                },
                {
                  Meta_Key: "Time",
                  Meta_Value: this.time_slot,
                },
                {
                  Meta_Key: "Amount",
                  Meta_Value: this.amount,
                },
                {
                  Meta_Key: "Description",
                  Meta_Value: this.description,
                }
              );
            } else {
            }
            this.isLoadingBookingDetails = false;
            this.userPageViewTable();
          var current_time = new Date(Date()).toLocaleString("en-us", {
            timeZone: "Asia/Yangon",
          });

        })
        .catch((viewParticularAppointmentPageError)=>{
          console.log(viewParticularAppointmentPageError);
          if(viewParticularAppointmentPageError.response.status == 401){
            this.auth_error_enable = true;
          }
          else{
            this.error_enable = true;
          }
        });


      	}
    }
  };
</script>

<style scoped>
.theme--light.v-divider {
  border: 1px solid #c9e5ff !important;
  width: 95%;
  margin: auto;
}
.css-title {
  font-weight: 300;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
}
.fileList{
  display: flex;
  flex-direction: column;
  background: #F4F6FA;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 90%;
  margin-left:auto;
  margin-right: auto;
  margin-top:2%;
  padding:8px
}
.fileNameClass{
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  justify-content: space-between;
}
.fileStatusUploadingClass{
  display: flex;
  flex-direction: row;
  margin: 0% !important;
  color: #F2994A;
}
.fileStatusCompletedClass{
  display: flex;
  flex-direction: row;
  margin: 0% !important;
  color: #27AE60;
}
.fileStatusFailedClass{
  display: flex;
  flex-direction: row;
  margin: 0% !important;
  color:#EB5757;
}
.v-dialog--fullscreen>.v-card{
  margin:50% 0% 0% 0% !important;
  max-width: 425px !important;
}
.v-dialog--fullscreen{
  overflow-y: hidden !important;
}
.fileUploadPopUpClass {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.content {
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  color: #333333;
  line-height: 24px;
}
.header {
  display: block;
  box-shadow: 0px 2px 15px 0px #00000026;
  padding: 16px 20px !important;
}
.viewAppointmentBtnClass {
  margin: 10px 20px;
  position: relative;
}
.JoinClass {
  margin: 20px;
}
.InsertFeedbackClass {
  margin: 20px 20px;
}
.fileAttachementsCard{
  margin-top: 8px;
  border-radius: 10px;
}
.attachementsTitle{
  font-size: 16px;
  color: #333333;
  font-style: normal;
  font-weight: 600;
  padding-top: 10px;
  padding-left:10px;
}
/* .fileTitileDiv{
  /* background: #E4F5FF !important;
} */
.individualFile{
  width: 100px;
}
.imgattachementClass{
  width: 40px;
}
.thumbnailattachementClass{
  width: 43px;
  height: 53px;
}
.attachementClass{
  width: 64px;
  height: 74px;
  border: #E0E0E0 solid 1px;
  padding: 10px;
  padding-bottom: 10px;
  margin: 15px;
  margin-bottom: 0px !important ;
  border-radius: 8px;
}
.fileNameClass{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 10px;
  font-size: 12px;
  color: #222B45;
}
.attachementWrapCalss{
  display: flex;
  flex-wrap: wrap;

}

.dots-cont {
  position: absolute;
  left: 32% !important;
  /* right: 0px;
  bottom: 0px; */
}
.dot {
   width: 4px;
   height:4px;
   background: #F2994A;
   display: inline-block;
   border-radius: 50%;
   right: 0px;
   bottom: 0px;
   margin: 0px 1.2px;
   position: relative;
   animation: jump 1s infinite;
}
.dots-cont:hover > .dot {
   /* position: relative; */
   /* bottom: 0px; */
   animation: none;
}
.dots-cont .dot-1 {
   -webkit-animation-delay: 100ms;
   animation-delay: 100ms;
}
.dots-cont .dot-2 {
   -webkit-animation-delay: 200ms;
   animation-delay: 200ms;
}
.dots-cont .dot-3 {
   -webkit-animation-delay: 300ms;
   animation-delay: 300ms;
}

@keyframes jump {
   0%   {bottom: 0px;}
   20%  {bottom: 5px;}
   40%  {bottom: 0px;}
}
/* @media (min-width: 800px){
  .viewAppointmentMainClass{
   width: 800px !important;
   text-overflow: ellipsis;
   margin-left: auto !important ;
  margin-right: auto !important ;

  }
  .viewAppointmentBtnClass{
    width: 400px !important;
  }
  } */
</style>